import Navbar from "./components/Navbar";
import Home from './pages/Home.js';
import {useRef} from 'react';
import {useState} from 'react';



function App() {

  const [success,setSuccess] = useState(null);

  const homeComponentRef = useRef(null);

  const handleSuccess = () => {
    setSuccess(true);
  }

  const handleScroll = (targetId) => {
    const homeComponent = homeComponentRef.current;

    if (homeComponent) {
      const targetElement = homeComponent.querySelector(`#${targetId}`);

      if (targetElement) {
        targetElement.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };

  return (
    <div className="app-container" id="scroll-target-1">
      <Navbar scrollToTarget={handleScroll} />
      <Home ref={homeComponentRef} scrollToTarget={handleScroll} success={success} handleSuccess={handleSuccess}/>
    </div>
  );
}

export default App;
