import './Home.css'
import headshot from '../resources/headshot.png'
import bootstrap from '../resources/bootstrap.svg'
import css from '../resources/css-3.svg'
import express from '../resources/express.png'
import github from '../resources/github.png'
import html from '../resources/html-5.svg'
import intellij from '../resources/intellij-idea.svg'
import java from '../resources/java.svg'
import javascript from '../resources/javascript.svg'
import mongodb from '../resources/mongodb.png'
import nodejs from '../resources/nodejs-icon.svg'
import react from '../resources/react.svg'
import spring from '../resources/spring-icon.svg'
import tailwind from '../resources/tailwindcss-icon.svg'
import vscode from '../resources/visual-studio-code.svg'
import EmailForm from '../components/Form'
import space from '../resources/spaceinvaders.png'
import buildhub from '../resources/buildhub.png'
import { useEffect } from 'react'

const Home = ({scrollToTarget, success, handleSuccess}) => {
    useEffect(() => {
        document.getElementById('overlay').style.display = 'none';
        document.querySelector('.github-logo').style.display = 'none';
        document.getElementById('overlay2').style.display = 'none';
        document.querySelector('.github-logo2').style.display = 'none';
    }
    )

    function showOverlay(num) {
        if(num==1){
        document.getElementById('overlay').style.display = 'block';
        document.querySelector('.github-logo').style.display = 'block';
        }
        else{
            document.getElementById('overlay2').style.display = 'block';
            document.querySelector('.github-logo2').style.display = 'block';
        }
      }
      
      function hideOverlay() {
        document.getElementById('overlay').style.display = 'none';
        document.querySelector('.github-logo').style.display = 'none';
        document.getElementById('overlay2').style.display = 'none';
        document.querySelector('.github-logo2').style.display = 'none';
      }

    return (
        <div className="home-container" >
            <div className="greet-container" >
                <h1>Hi <span class="wave">👋</span>,</h1> 
                <h1>I'm Sam,</h1>
                <h1>Full-Stack Developer</h1>
            </div>
            <div className="greet-desc">
                <h2>Expertise in React.js, Express.js, Node.js, JavaScript, Java, and Spring Boot. Passionate about creating seamless user experiences and solving complex challenges. Let's build something amazing together! 👨‍💻 </h2>
            </div>

            <div className="image-frame">
                <img src={headshot} alt="me" />
            </div>

            <div className="hirebutton">
            <a href="#scroll-target-4" onClick={scrollToTarget}>
            <button className="btn btn-outline">HIRE ME</button>
            </a>
            <a href="#scroll-target-3" onClick={scrollToTarget}>
            <button className="btn btn-outline" id="hello">SEE MY PROJECTS</button>
            </a>
            </div>

            <div className="techstack" id="scroll-target-2">
                <h1>My Tech Stack</h1>
            </div>
            <div className="techdesc">
            <h1>Technologies I've been working with recently</h1>
            </div>

            <div className="stacklogos">
            <img src={html} alt="me" />
            <img src={css} alt="me" />
            <img src={javascript} alt="me" />
            <img src={react} alt="me" />
            <img src={nodejs} alt="me" />
            <img src={express} alt="me" />
            <img src={mongodb} alt="me" />
            <img src={java} alt="me" />
            <img src={spring} alt="me" />
            <img src={intellij} alt="me" />
            <img src={vscode} alt="me" />
            <img src={bootstrap} alt="me" />
            <img src={tailwind} alt="me" />
            <img src={github} alt="me" />
            </div>

            <div className="projects" id="scroll-target-3">
                <h1>My Projects</h1>
            </div>
            <div className="projectsdesc">
                <h1>A sneak peak into some of my past work. I'm always coding on my free time, so expect to see this list grow!</h1>
            </div>

            <div className="projectscontainer" >
            <div className="project1">
                {/*onMouseOver={showOverlay} onMouseOut={hideOverlay}*/}
                <div className="project1card" onMouseOver={() =>showOverlay(1)} onMouseOut={hideOverlay}>
                    
                    <div id="overlay"></div>
                    <a href="https://github.com" target="_blank">
                    <img src={github} class="github-logo" alt="github2"></img>
                    </a>
                    
                <img src={buildhub} alt="soon"></img>
                </div>

                <h1>Build Hub</h1>
                <h2>Explore Build Hub, my latest full-stack project crafted with the MERN stack. This dynamic web application is tailored for contractors, offering seamless user authentication for secure sign-up and login experiences. Build Hub empowers contractors to effortlessly create, manage, and send professional invoices to clients, streamlining the invoicing process with efficiency and ease.</h2>

            </div>
            <div className="project2">
                <div className="project2card" onMouseOver={() => showOverlay(2)} onMouseOut={hideOverlay}>
                    <div id="overlay2"></div>
                    <a href="https://github.com" target="_blank">
                    <img src={github} class="github-logo2" alt="github2"></img>
                    </a>
                    <img src={space} alt="soon"></img>
                </div>
                <h1>Space Invaders</h1>
                <h2>Dive into my Space Invaders project, a Java-based gaming masterpiece leveraging the power of Java Swing. Demonstrating proficiency in core Java and object-oriented programming, this project showcases the timeless classic with a modern twist. With an emphasis on multithreading, it not only brings the nostalgic joy of gaming but also highlights advanced programming techniques, making it a testament to my skills in Java development.</h2>
            </div>
            </div>

            <div className="contactcontainer" id="scroll-target-4">
                
                <div className="contactbox">

                    <h1>Let's connect</h1>
                    <h2> I'm excited about the opportunity to connect with you. If you have any questions, want to discuss a potential collaboration, or simply want to say hello, I'd love to hear from you. Feel free to use the form below to reach out, and I'll get back to you as soon as possible.</h2>

                </div>
                <div className="formbox">
                {success && <div role="alert" className="alert alert-success">
  <             svg xmlns="http://www.w3.org/2000/svg" className="stroke-current shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
                <span>Your message has been sent!</span>
                </div> }
                    <h1>Send me a message</h1>
                    <EmailForm handleSuccess={handleSuccess}/>
                </div>
            </div>            
        </div>
      );
}
 
export default Home;