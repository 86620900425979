import './Form.css';
import emailjs from '@emailjs/browser';
import React, { useRef, useState } from 'react';


function EmailForm({handleSuccess}) {

  const form = useRef();

  const [disabled, setDisabled] = useState("");

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_apo4z1i', 'template_nvqr2wr', form.current, 'BaCPhTJoMtTjBReja')
      .then((result) => {
          console.log(result.text);
          setDisabled("disabled");
          handleSuccess();
      }, (error) => {
          console.log(error.text);
      });
  };

  return (
    <div className="emailform">
    <form ref={form} onSubmit={sendEmail}>
      <div className="form-name">
        <label htmlFor="fullName">Full Name</label>
        <input type="text" id="fullName" placeholder="Enter your name" className="input input-bordered w-full max-w-xs" name="user_name"/>
      </div>

      <div className="form-email">
        <label htmlFor="email">Email</label>
        <input type="text" id="email" placeholder="Enter your email" className="input input-bordered w-full max-w-xs" name="user_email" />
      </div>

      <div className="form-message">
        <label htmlFor="message">Message</label>
        <textarea id="message" className="textarea textarea-bordered" placeholder="Enter your message" name="message"></textarea>
      </div>

      <button type="submit" className="btn btn-outline btn-primary" disabled={disabled}>Submit</button>
    </form>
  </div>
  );
}

export default EmailForm;