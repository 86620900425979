import './Navbar.css'

const Navbar = ({scrollToTarget}) => {
    return ( <div>
        <div className="nav-container">
            <a href="#scroll-target-1" onClick={scrollToTarget}>
            <h1>About me</h1>
            </a>
            <a href="#scroll-target-2" onClick={scrollToTarget}>
            <h1>Skills</h1>
            </a>
            <a href="#scroll-target-3" onClick={scrollToTarget}>
            <h1>Projects</h1>
            </a >
            <a href="#scroll-target-4" onClick={scrollToTarget}>
            <h1>Contact</h1>
            </a>
            

        </div>
    </div> );
}
 
export default Navbar;